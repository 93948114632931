<template>
  <b-row class="match-height">
    <b-col
      cols="12"
    >
      <p class="mb-2">
        <span>احصائيات براعم لعامي 2021 2022</span>
       
      </p>
    </b-col>
    <b-col cols="12">
         <apex-line-chart />
    </b-col>
    <b-col md="6">
      <apex-donut-chart />
    </b-col>
    
  </b-row>
</template>

<script>
import { BRow, BCol, BLink } from 'bootstrap-vue'
import ApexLineChart from './ApexLineChart.vue'

import ApexDonutChart from './ApexDonutChart.vue'
export default {
  components: {
    BRow,
    BCol,
    BLink,

   ApexLineChart,
    ApexDonutChart,
  
  },
  props:{
     data: {
      type: Array,
      
    },
  },
  watch: {
    'data': function () {
      //console.log("stat",this.data)
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>